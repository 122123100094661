<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item
        disabled
      >
        <v-list-item-title class="text-captions-1">
          <IconShare width="14" class="mr-2 gray-60--text"/>
          Share
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="copyProjectLink">
        <v-list-item-title class="text-captions-1">
          <IconPaperclip width="16" class="mr-2 gray-60--text"/>
          Copy Link
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="styleItem.total_files === 0"
        @click="downloadFile"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download Folder
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!getPermission($route.params.project_id).design['can-delete-style-design']"
        @click="deleteStyle">
        <v-list-item-title class="text-captions-1">
          <IconDelete width="14" class="mr-2 gray-60--text"/>
          Delete
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!getPermission($route.params.project_id).design['can-rename-style-design']"
        @click="styleItem.showRenameInput = true">
        <v-list-item-title class="text-captions-1">
          <IconPen width="14" class="mr-2 gray-60--text"/>
          Rename
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import {copyTextToClipboard} from "@/utils/helpers";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FilesStyleMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconPaperclip: () => import('@/components/icons/IconPaperclip'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconPen: () => import('@/components/icons/IconPen'),

    UiMenu,
    UiBtn
  },
  props: {
    styleItem: {
      type: Object,
      default: null,
    },
    stylesList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
  },
  methods: {
    copyProjectLink() {
      copyTextToClipboard(`${window.location.origin}/files/project/${this.$route.params.project_id}/style/${this.styleItem.id}`);
    },
    async downloadFile() {
      try {
        const files = await this.$api.projectModuleDesign.downloadStyleFiles(this.$route.params.project_id, this.styleItem.id)

        const fileURL = URL.createObjectURL(new Blob([files]));

        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = `${this.styleItem.title}.zip`;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(fileURL);

        this.$toast.open({
          message: 'Your files are being compressed',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        if (error.response.status === this.$config.http.NOT_FOUND) {
          this.$toast.open({
            message: 'There are no files in Approved status',
            type: 'error',
            position: 'top-right'
          });
        } else {
          this.$toast.open({
            message: 'Oops! Something went wrong!',
            type: 'error',
            position: 'top-right'
          });
        }
      }
    },
    deleteStyle() {
      if (!this.getPermission(this.$route.params.project_id).design['can-delete-style-design']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete <br> “${this.styleItem.title}“?`,
          confirmBtnText: 'Delete'
        },
        handlers: {
          onConfirm: async () => {
            this.styleItem.loading = true;
            this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteMultiple(this.$route.params.project_id, this.$route.query.module, {styles: [this.styleItem.id]});

              const stylesList = this.stylesList.filter(style => style.id !== this.styleItem.id);
              this.$emit('updateStylesList', stylesList);
            } catch (error) {
              this.styleItem.loading = false;
              console.error(error);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        }
      });
    },
  },
}
</script>
