<template>
  <div class="bulk-actions d-flex align-center">
    <div class="bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selectedStyles.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1">
        {{ stylesList.length }} Total Projects
        <span class="ml-1 font-weight-semi-bold">({{ stylesList.length }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center">
        <UiBtn outlined color="accent"
               :disabled="!getPermission(this.$route.params.project_id).design['can-delete-style-design']" @click="deleteProjects">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
        <UiBtn outlined color="accent" class="mx-4" disabled>
          <IconShare width="14" class="mr-2"/>
          Share
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'BulkActionsBar',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconShare: () => import('@/components/icons/IconShare'),

    UiBtn,
  },
  props: {
    stylesList: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
    selectedStyles() {
      return this.stylesList.filter(style => style.checked);
    },
  },
  methods: {
    toggleAll(val) {
      const styles = this.stylesList.map(style => ({...style, checked: val}));
      this.$emit('updateStylesList', styles);
    },
    getLoadingStylesId(styles) {
      const ids = styles.map(style => style.id);

      const loadingStyles = this.stylesList.map(style => ids.includes(style.id) ? {...style, loading: true} : style);
      this.$emit('updateStylesList', loadingStyles);

      return ids;
    },
    deleteProjects() {
      if (!this.getPermission(this.$route.params.project_id).design['can-delete-style-design']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete <br> ${this.selectedStyles.length === 1 ? '“'+this.selectedStyles[0].title+'“' : 'selected styles'}?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            const ids = this.getLoadingStylesId(this.selectedStyles);

            try {
              await this.$api.projectModuleDesign.deleteMultiple(this.$route.params.project_id, this.$route.query.module, {styles: ids})

              const styles = this.stylesList.reduce((acc, style) => {
                if (!ids.includes(style.id)) {
                  acc.push({...style, loading: false});
                }
                return acc;
              }, []);
              this.$emit('updateStylesList', styles);
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.bulk-actions {
  .bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>
